import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import p1 from '../../../../img/eduPg/영어.jpg';
import p2 from '../../../../img/eduPg/오감.jpg';
import p3 from '../../../../img/eduPg/음악.jpg';
import p4 from '../../../../img/eduPg/창의.jpg';
import p5 from '../../../../img/eduPg/초등.jpg';
import p6 from '../../../../img/eduPg/체육.png';
import p7 from '../../../../img/eduPg/코딩.jpg';
import p8 from '../../../../img/eduPg/행복.jpg';
const mock = [
  {
    image: p1,
    description:
      '입체적인 그림책 수업과 키자니아 직업체험을 병행하여 실제상황에서 사용할 수 있는 영어 표현을 배우고, 여러 문제의 상황을 해결해나가는 크레카말들을 통해 발표능력과 문제해결능력을 키우게 됩니다.',
    title: '[특성화교육(영어, 크레카)]',
  },
  {
    image: p8,
    description: '사회정서 학습의 핵심역량을 배우고 인간의 본질적인 고유성을 기르며, 전인교육의 일환으로 아이들의 조화로운 성장발달을 돕습니다.',
    title: '[우리아이 행복프로젝트]',
  },
  {
    image: p3,
    description:
      '음악활동(1인 1악기)를 통해 연주의 즐거움을 느끼고, 기본운동과 더불어, 줄넘기, 볼림, 유니바, 도미노 양궁, 비치볼 등의 체험으로 폭 넓은 운동놀이의 경험을 갖게 됩니다.',
    title: '[예체능 교육]',
  },
  {
    image: p2,
    description:
      '시각과 청각, 촉각 오감을 발달시킬 수 있는 교구와 실물자료를 활용하여 잠재된 아이들의 능력을 이끌어 내어 건강하고 밝은 인성을 기를 수 있도록 돕습니다.',
    title: '[오감발달 교육]',
  },
  {
    image: p7,
    description: '로봇을 활용한 코딩교육을 통해 디지털문화에 부합하는 사고방법을 배우고, 미래사회에 필요한 \'창의융합형 인재\' 양성을 위한 창의교구 활동이 이루어집니다.',
    title: '[창의융합(문제해결력)교육]',
  },
  {
    image: p5,
    description:
      '새로운 환경인 초등학교에 대한 긍정적인 인식을 확립하기 위하여 \'미리 경험하는 예비 초등과정\'을 진행합니다.',
    title: '[초등연계 교육]',
  },
];

const FeaturedArticles = () => {
  const theme = useTheme();
  return (
    <Box >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        marginBottom={4}
      >
        <Box>
          <Typography fontWeight={700} variant={'h4'} gutterBottom
                      style={{color: '#43a047',
                        fontFamily:'"Noto Sans KR", sans-serif',
                      }}
          >
            교육 프로그램
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              component={'a'}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={0}
                sx={{ bgcolor: 'transparent', backgroundImage: 'none' }}
              >
                <CardMedia
                  image={item.image}
                  title={item.title}
                  sx={{
                    height: { xs: 300, md: 360 },
                    position: 'relative',
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
                <Box
                  width={'90%'}
                  margin={'0 auto'}
                  display={'flex'}
                  flexDirection={'column'}
                  boxShadow={3}
                  borderRadius={2}
                  bgcolor={'background.paper'}
                  position={'relative'}
                  zIndex={3}
                  sx={{ transform: 'translateY(-30px)' }}
                >
                  <Box component={CardContent} position={'relative'}>
                    <Typography variant={'h6'} gutterBottom
                                style={{fontFamily:'"Noto Sans KR", sans-serif'}}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary"
                                style={{fontFamily:'"Noto Sans KR", sans-serif'}}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeaturedArticles;
