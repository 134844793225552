import React, {useEffect, useState} from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import SideBar from "./sideBar"
import "./components/css/main.css"
import Home from "./components/app/Home"
import Intro from "./components/app/Intro";
import News from "./components/app/News";
import ChangeNickName from "./components/app/common/ChangeNickName";
import ChaneGrade from "./components/app/common/ChangeGrade"
import QuillView from "./components/app/QuillView"
// import {KakaoLogin2} from "./components/app/kakaoLogin"

//Edu
import Edu from "./components/app/edu/Edu";
//story
import Story from "./components/app/story/Story";
import Program from "./components/app/story/program/Program"
import QuillProgram from "./components/app/story/program/QuillProgram"
import ProgramView from "./components/app/story/program/ProgramView"
import ProgramUpdate from "./components/app/story/program/ProgramUpdate"
import ChangeGrade from "./components/app/common/ChangeGrade";

function Main() {
  const [hello, setHello] = useState('');

  // useEffect(() => {
  //   axios.get('/api/test')
  //       .then((res) => {
  //         setHello(res.data);
  //       })
  // }, []);

return (
    <main className='Mains'>
        <div id='Mains-left'>
            <SideBar />
        </div>
    <div>
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route path="/intro" element={<Intro/>}/>
                <Route path={"/changeNickName"} element={<ChangeNickName/>}/>
                <Route path={"/changeGrade"} element={<ChangeGrade/>}/>
                <Route path={"/edu"} element={<Edu/>}/>
                <Route path="/write" element={<QuillView/>}/>

                <Route path={"/story"} element={<Story/>}/>
                <Route path="/story/program" element={<Program/>}/>
                <Route path="/story/program/write" element={<QuillProgram/>}/>
                <Route path="/story/program/view" element={<ProgramView/>}/>
                <Route path="/story/program/update" element={<ProgramUpdate/>}/>


                <Route path="/news" element={<News/>}/>
                {/*<Route path="/kakaoLogin" element={<KakaoLogin2/>}/>*/}
            </Routes>

        </div>
    </main>
);
}
export default Main;