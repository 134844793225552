import React from 'react';

import {Insert} from './components';
const ProgramInsert = () => {
  return (
          <Insert />
  );
};

export default ProgramInsert;
