import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Main as MainSection} from './components';

const SchoolEnv = () => {
  const theme = useTheme();
  return (
    <Main>
      <Box bgcolor={'alternate.main'} sx={{bgcolor:'#EEE8AA'}}>
      <Container>
        <MainSection />
      </Container>
      </Box>
    </Main>
  );
};

export default SchoolEnv;
