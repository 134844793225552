import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Container from 'components/Container';

const mock = [
  {
    title: 'call',
    subtitle:
      '051-505-9330',
    suffix: '+',
  },
  {
    title: 'E-mail',
    subtitle:
      'pns9330@hanmail.net',
    suffix: '+',
  },

];

const MapHero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LeftSide = () => {

    return (
      <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
        <Box marginBottom={2}>
          <Typography variant={'h5'} sx={{ fontWeight: 700 }} gutterBottom>
            오시는 길
          </Typography>
          <Typography color="text.secondary">
            부산광역시 동래구 쇠미로17번길 8 부산경찰청어린이집
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid key={i} item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                <Box fontWeight={600}>
                  {item.title}
                </Box>
              </Typography>
              <Typography color="text.secondary" component="p">
                {item.subtitle}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={4} marginTop={2}>
          <Typography variant="h6" gutterBottom>
            <Box fontWeight={600}>
              cafe
            </Box>
          </Typography>
          <Typography color="text.secondary" component="p">
            https://cafe.daum.net/pns9330
          </Typography>
        </Grid>
      </Box>
    );
  };

  const RightSide = () => {
    return (
      <iframe
        width="100%"
        height="100%"
        frameBorder="0"
        title="map"
        marginHeight={0}
        marginWidth={0}
        scrolling="no"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3260.420323719368!2d129.05076467653157!3d35.19599787274779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356894b9b726fd41%3A0xb3fd220a56d87bf6!2z67aA7IKw6rK97LCw7LKt7Ja066aw7J207KeR!5e0!3m2!1sko!2skr!4v1716559038041!5m2!1sko!2skr"
        style={{
          minHeight: 300,
          filter:
            theme.palette.mode === 'dark'
              ? 'grayscale(0.5) opacity(0.7)'
              : 'none',
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
        >
          <Box width={1} order={{ xs: 2, md: 1 }}>
            <Container>
              <LeftSide />
            </Container>
          </Box>
          <Box
            sx={{
              flex: { xs: '0 0 100%', md: '0 0 50%' },
              position: 'relative',
              maxWidth: { xs: '100%', md: '50%' },
              order: { xs: 1, md: 2 },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: '50vw' },
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    left: '0%',
                    width: 1,
                    height: 1,
                    position: { xs: 'relative', md: 'absolute' },
                  }}
                >
                  <RightSide />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <Divider />
    </Box>
  );
};

export default MapHero;
