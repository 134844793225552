import React from 'react';

import {Insert} from './components';
const NoticeInsert = () => {
  return (
          <Insert />
  );
};

export default NoticeInsert;
