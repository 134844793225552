
import { BrowserRouter} from "react-router-dom";
import Nav from "./components/app/Nav"
import Main from "././Main"
import Footer from "./components/app/Footer"
import Banner from "./components/app/Banner";
import {Header,H} from "./components/app/Header"
import Page from "./components/Page"
import Routes from "./Routes"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './font/Font.css'

const theme = createTheme({
  typography: {
    fontFamily: ['"Noto Sans KR", sans-serif',
      '"Black Han Sans", sans-serif',
      '"Gamja Flower", sans-serif',
      '"Sunflower", sans-serif',
    ].join(','),
  },
})
function App() {

return (
     <ThemeProvider theme={theme}>
    <Page>
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    </Page>
      </ThemeProvider>

    // <div>
    // <BrowserRouter>
    //     <Header />
    //     <H/>
    //     <Nav/>
    //     <Banner/>
    //     <div>
    //         <Main />
    //     </div>
    //     <Footer/>
    //
    // </BrowserRouter>
    // </div>
);
}
export default App;