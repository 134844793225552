import React from 'react';

import {Insert} from './components';
const LetterInsert = () => {
  return (
          <Insert />
  );
};

export default LetterInsert;
