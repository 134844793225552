
import "../../../css/main.css"
import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import styled from 'styled-components'

    function Main() {

        return (

                <div className="py-4">
                    <div className="container">
                        <div>
                            게시판 목록 출력
                            {BoardList()}
                        </div>
                    </div>
                </div>

        );
    }

const BoardList = () => {
    const [boardList, setBoardList] = useState([]);
    const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
    const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));

    //검색
    const titleSearch= useRef(null);

    //페이징 처리
    const [page, setPage] = useState(1);
    const [items, setItems] = useState(5);
    const handlePageChange = (page) => { setPage(page); };
    const itemChange = (e) => {
        setItems(Number(e.target.value))

    }

    const getBoardList = async () => {
        try {
            const resp = await (await axios.get("/api/story/program", {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })).data;
            setBoardList(resp); // 3) boardList 변수에 할당

        } catch (error) {
            alert(error.response.data.resultMsg);
            window.location.href = "/"
        }
    }

    //검색버튼 클릭
    const titleSearchClick = async() => {
        try {
            if (titleSearch ==null){
                getBoardList();
            }else {
                const resp = await (await axios.get("/api/program/search", {
                    params:{title:titleSearch.current},
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })).data;
                setBoardList(resp); // 3) boardList 변수에 할당
            }
        } catch (error) {
            alert(error.response.data.resultMsg);
            window.location.href = "/"
        }
    }

    const titleSearchChane=(e) =>{
        // setTitleSearch(e.target.value);
        titleSearch.current = e.target.value
    }

    const  checkTokenExpire = () =>{
        const parseTokenExpiresIn = tokenExpiresIn;
        if(!parseTokenExpiresIn){
            
            return null;
        }

        const now = new Date();

        if (now.getTime()>parseTokenExpiresIn){
            localStorage.removeItem('accessToken');
            localStorage.removeItem('tokenExpiresIn');
            
            return null
        }
        
        return parseTokenExpiresIn
    }


    useEffect(() => {
        getBoardList(); // 1) 게시글 목록 조회 함수 호출
    }, []);

    useEffect(() => {
        checkTokenExpire(); // 1) 게시글 목록 조회 함수 호출
    }, []);

    return (
        <div>
            <div className="input-group mb-3">
                <input type="text" className="form-keyword" placeholder="제목검색" key={titleSearch} onChange={titleSearchChane}></input>
                <button className="btn btn-primary">
                    <a className="btn btn-primary" onClick={titleSearchClick}>검색 </a>
                </button>
            </div>
            <div>
                <button className="btn btn-primary">
                    <a href="/story/program/write" role="button" className="btn btn-primary">글 등록 </a>
                </button>
            </div>

            {/*블로그 글 테스트*/}

            <div>
                <select name="items" onChange={itemChange}>
                    <option value="5">5개</option>
                    <option value="10">10개</option>
                    <option value="15">15개</option>
                    <option value="20">20개</option>
                </select>
            </div>
            {
                boardList.slice(
                    items * (page - 1),
                    items * (page - 1) + items
                ).map((v, i) => {
                    return (
                        <div key={i}>
                            <h3><Link to='/story/program/view' state={{boardId:v.id}}>{v.title}</Link></h3>
                            <div>userId = {v.userId}, 닉네임 = {v.author}</div>
                            <div>조회수 =  {v.views} 개시일 = {v.createdDate}</div>
                        </div>
                    )
                })
            }
            <PaginationBox>
                <Pagination
                    activePage={page}
                    itemsCountPerPage={items}
                    totalItemsCount={boardList.length - 1}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}>
                </Pagination>
            </PaginationBox>
        </div>
    );
};

const PaginationBox = styled.div`
  .pagination { display: flex; justify-content: center; margin-top: 15px;}
  ul { list-style: none; padding: 0; }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem; 
  }
  ul.pagination li:first-child{ border-radius: 5px 0 0 5px; }
  ul.pagination li:last-child{ border-radius: 0 5px 5px 0; }
  ul.pagination li a { text-decoration: none; color: #337ab7; font-size: 1rem; }
  ul.pagination li.active a { color: white; }
  ul.pagination li.active { background-color: #337ab7; }
  ul.pagination li a:hover,
  ul.pagination li a.active { color: blue; }
`
export default Main;