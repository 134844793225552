import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { BoardList} from './components';
import Page from './components/components/Page'
const DisProgram = () => {
  const theme = useTheme();
  return (
    <Main>
    <Page>
      <Box sx={{bgcolor:'#EEE8AA'}}>
        <Box
          sx={{
            // marginTop: -13,
            paddingTop: 5,
            marginBottom: -10
          }}
        >
        </Box>
        <Container maxWidth={800}>
          <BoardList />
        </Container>
      </Box>
    </Page>
    </Main>
  );
};

export default DisProgram;
