/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import axios from 'axios';


const Form = () => {
  const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
  const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));
  const nickName = useRef(null);
  const [getName,setGetName] = useState(null);
  const grade = useRef(null);
  const saveNickName = (e) => {
    nickName.current = e.target.value
  }

  const getUserInfo = async ()=>{
    try {
      const resp = await (await axios.get("/api/getUserInfo",{
        params:{userId:tokenExpiresIn.userId},
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      setGetName(resp.data.nickName);
      nickName.current = resp.data.nickName;
      grade.current = resp.data.role;
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
    return baseNickName();
  }

  const baseNickName = () =>{
    return(
    <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
      현재 닉네임 : {getName}
    </Typography>);
  }
  const insertButton = async () => {

      if(nickName.current ==="" || nickName.current == null){
        alert("닉네임을 입력해주세요");
      } else {
    try {//백엔드에 데이터 보내기
      setGetName(nickName.current);
      let data = {
        id: tokenExpiresIn.userId,
        nickName: nickName.current,
      }

      const result = await axios.post('/api/nickName', JSON.stringify(data), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      var newTokenExpiresIn = JSON.parse(localStorage.getItem('tokenExpiresIn'));
      newTokenExpiresIn.nickName = result.data.nickName;
      // localStorage.removeItem("tokenExpiresIn");
      localStorage.setItem("tokenExpiresIn", JSON.stringify(newTokenExpiresIn));

      alert('변경되었습니다.');
      window.location.href = "/"
    } catch (error) {
      alert(error.response.data.resultMsg);
      // window.location.reload();
      window.location.href = "/"
    }
  }
  }

  const changeGradeBtn = () =>{
    if(grade.current==='TEACHER') {
      return (
        <Box marginBottom={{ xs: 1, sm: 0 }}>
        <Button
          size={'large'}
          variant={'outlined'}
          component={Link}
          href={'/changeGrade'}
          fullWidth
        >
          등급 변경(관리자용)
        </Button>
      </Box>
    )}
    else {
      return null;
    }
  }

  const  checkTokenExpire = () =>{
    const parseTokenExpiresIn = tokenExpiresIn;
    if(!parseTokenExpiresIn){
      alert('로그인이 필요합니다..');
      window.location.href = "/"
      return null;
    }

    const now = new Date();

    if (now.getTime()>parseTokenExpiresIn){
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenExpiresIn');
      alert('시간만료 로그인이 필요합니다..');
      window.location.href = "/"
      return null
    }
    return parseTokenExpiresIn
  }

  //
  // useEffect(() => {
  //   getUserInfo(); // 1) 게시글 목록 조회 함수 호출
  // }, []);

  useEffect(() => {
    checkTokenExpire();
  }, []);

  useEffect(() => {
    baseNickName();
    changeGradeBtn();
  }, [getUserInfo()]);

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          부산경찰청어린이집
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          마이페이지 (닉네임 변경)
        </Typography>
      </Box>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            {baseNickName()}
            <TextField
              variant="outlined"
              fullWidth
              key={nickName.current}
              onChange={saveNickName}
              placeholder="ex)24년생 이유준엄마"
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              {changeGradeBtn()}
              <Button size={'large'} variant={'contained'} type={'submit'} onClick={insertButton}>
                변경하기
              </Button>
            </Box>
          </Grid>
        </Grid>
    </Box>
  );
};

export default Form;
