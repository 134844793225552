import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {Link, useLocation} from 'react-router-dom';
import Pagination from "react-js-pagination";
import styled from 'styled-components'
function Main() {

    return (

        <div className="py-4">
            <div className="container">
                <div>
                    상세페이지 출력
                    {BoardView()}
                </div>
            </div>
        </div>

    );
}

const BoardView = () => {
    const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
    const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));
    const [detailView,setDetailView] = useState({id: null,title: null, author: null, views: null,boardList:null, content: null, viewFileDtoList:[],commentList:[]});
    let location = useLocation();
    const boardId = location.state.boardId;
    const comment = useRef("");

    //페이징 처리
    const [page, setPage] = useState(1);
    const [items, setItems] = useState(5);
    const handlePageChange = (page) => { setPage(page); };

    const getBoardView = async () => {
        try {
            const resp = await (await axios.get("/api/program/view", {
                params:{id:boardId},
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }));
            setDetailView(resp.data); // 3) boardList 변수에 할당
        } catch (error) {
            alert(error.response.data.resultMsg);
            window.location.href = "/"
        }
    }
    const fileName = () => {
        const result = [];
        for (let i = 0; i < detailView.viewFileDtoList.length; i++) {
            result.push(<div key={i}>
                <button onClick={() => downloadFile(detailView.viewFileDtoList[i].url,detailView.viewFileDtoList[i].name)}>{detailView.viewFileDtoList[i].name}</button>
            </div>);
        }
        return result;
    };

    const downloadFile=async (url,fileName) =>{

        fetch(url, {
            method: "GET",
        })
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const blobURL = URL.createObjectURL(blob);

                const aTag = document.createElement("a");

                aTag.href = blobURL;
                aTag.download = fileName;

                aTag.click();
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const CommentSet = (e) => {
        comment.current = e.target.value
    }

    const saveComment = async() =>{
        try {
            let data = {
                content: comment.current,
                boardId: boardId,
                userId: tokenExpiresIn.userId,
                writer: tokenExpiresIn.nickName,
            }
            const result = await axios.post('/api/saveCommnet/program', JSON.stringify(data), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                }
            });
            alert('등록되었습니다.');
            window.location.reload();
        }catch (error){
            alert(error.response.data.resultMsg);
            window.location.reload();
        }
    }

    const updateBtn = () =>{
        if(detailView.userId ==tokenExpiresIn.userId){
            return(<button><Link to={'/story/program/update'} state={{boardId:detailView.id}}>수정</Link></button>);
        }
        return null;
    }
    const deleteBtn = () =>{
        if(detailView.userId ==tokenExpiresIn.userId){
            return(<button type="button" onClick={deleteBoard}>삭제</button>);
        }
        return null;
    }

    const deleteBoard = async() =>{
        try {
            const result =await (await axios.get('/api/delete/program', {
                params:{id:boardId},
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }));
            alert('삭제되었습니다.');
            window.location.href = "/story/program/garden";
        }catch (error){
            alert(error.response.data.resultMsg);
            window.location.reload();
        }
    }

    const  checkTokenExpire = () => {
        const parseTokenExpiresIn = tokenExpiresIn;
        if(!parseTokenExpiresIn){
            
            return null;
        }

        const now = new Date();

        if (now.getTime()>parseTokenExpiresIn){
            localStorage.removeItem('accessToken');
            localStorage.removeItem('tokenExpiresIn');
            
            return null
        }
        
        return parseTokenExpiresIn
    }


    useEffect(() => {
        getBoardView(); // 1) 게시글 상세정보 함수 호출
    }, []);

    useEffect(() => {
        updateBtn(); // 1) 게시글 목록 조회 함수 호출
        fileName();
    }, [detailView]);

    useEffect(() => {
        checkTokenExpire(); // 권한체크
    }, []);

    return(
        <div>
            <div>
                {updateBtn()}
                {deleteBtn()}
                {fileName()}
            </div>
            <div>
                {/*{fileName(files)}*/}
            </div>
            <div>
                <h3>{detailView.title}</h3>
                <p>{detailView.boardList}</p>
                <p>{detailView.author}</p>
                <p>{detailView.views}</p>
                {/*<div>내용 {detailView.current.content}</div>*/}
                <div dangerouslySetInnerHTML={{__html: detailView.content}} style={{width: '500px'}}></div>
            </div>

            <div>댓글</div>
            <div>
                <textarea type='text' placeholder="내용을 입력하세요" key={comment} onChange={CommentSet} > </textarea>
                <div>
                    <button type="button" onClick={saveComment}>등록</button>
                </div>
            </div>
            {/*블로그 글 테스트*/}

            {
                detailView.commentList.slice(
                    10 * (page - 1),
                    10 * (page - 1) + 10
                ).map((v, i) => {
                    return (
                        <div key={i}>
                            <h3>{v.writer},{v.createdDate}</h3>
                            <div>userId = {v.content}</div>
                        </div>
                    )
                })
            }
            <PaginationBox>
                <Pagination
                    activePage={page}
                    itemsCountPerPage={items}
                    totalItemsCount={detailView.commentList.length - 1}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}>
                </Pagination>
            </PaginationBox>
        </div>
    );
};

const PaginationBox = styled.div`
  .pagination { display: flex; justify-content: center; margin-top: 15px;}
  ul { list-style: none; padding: 0; }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem; 
  }
  ul.pagination li:first-child{ border-radius: 5px 0 0 5px; }
  ul.pagination li:last-child{ border-radius: 0 5px 5px 0; }
  ul.pagination li a { text-decoration: none; color: #337ab7; font-size: 1rem; }
  ul.pagination li.active a { color: white; }
  ul.pagination li.active { background-color: #337ab7; }
  ul.pagination li a:hover,
  ul.pagination li a.active { color: blue; }
`
export default Main;