import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Pagination from 'react-js-pagination';
import styled from 'styled-components'

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const headCells = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: '제목',
  },
  {
    id: 'author',
    numeric: true,
    disablePadding: false,
    label: '작성자',
  },
  {
    id: 'views',
    numeric: true,
    disablePadding: false,
    label: '조회수',
  },
  {
    id: 'createdDate',
    numeric: false,
    disablePadding: false,
    label: '개시일',
  },
];
//게시판 헤더
function EnhancedTableHead(props) {
  const { order, orderBy } =
    props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/*<Checkbox*/}
          {/*  color="primary"*/}
          {/*  indeterminate={numSelected > 0 && numSelected < rowCount}*/}
          {/*  checked={rowCount > 0 && numSelected === rowCount}*/}
          {/*  onChange={onSelectAllClick}*/}
          {/*  inputProps={{*/}
          {/*    'aria-label': 'select all desserts',*/}
          {/*  }}*/}
          {/*/>*/}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/*head부분에 정렬 화살표 나오는 유무*/}
            {/*<TableSortLabel*/}
            {/*  active={orderBy === headCell.id}*/}
            {/*  direction={orderBy === headCell.id ? order : 'asc'}*/}
            {/*  onClick={createSortHandler(headCell.id)}*/}
            {/*>*/}
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            {/*</TableSortLabel>*/}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const BoardList = () => {
  const [boardList, setBoardList] = useState([]);
  const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
  const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));
  //검색
  const titleSearch= useRef(null);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const titleSearchChane=(e) =>{
    // setTitleSearch(e.target.value);
    titleSearch.current = e.target.value
  }
  //검색버튼 클릭
  const titleSearchClick = async() => {
    try {
      //url끝자리 잘라와서 게시판 목록 구분
      const url = document.location.href;
      const splitUrl = url.split('/');
      const location = splitUrl[splitUrl.length - 1];

      if (titleSearch.current ==""||titleSearch.current==null){
        getBoardList();
      }else {
        const resp = await (await axios.get("/api/edu/eduPlan/search", {
          params:{title:titleSearch.current,
                  boardName:location,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })).data;
        setBoardList(resp); // 3) boardList 변수에 할당
      }
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/edu/eduPlan/eng"
    }
  }

  const getBoardList = async () => {
    try {
      //url끝자리 잘라와서 게시판 목록 구분
      const url = document.location.href;
      const splitUrl = url.split('/');
      const location = splitUrl[splitUrl.length - 1];

      const resp = await (await axios.get("/api/edu/eduPlan", {
        params:{boardName:location},
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      })).data;
      setBoardList(resp); // 3) boardList 변수에 할당

    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }

  const  checkTokenExpire = () =>{
    const parseTokenExpiresIn = tokenExpiresIn;
    if(!parseTokenExpiresIn){
      return null;
    }

    const now = new Date();

    if (now.getTime()>parseTokenExpiresIn){
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenExpiresIn');
      return null
    }
    return parseTokenExpiresIn
  }


  useEffect(() => {
    getBoardList(); // 1) 게시글 목록 조회 함수 호출
  }, []);

  useEffect(() => {
    checkTokenExpire(); // 1) 게시글 목록 조회 함수 호출
  }, []);

//페이징 처리
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(10);
  const handlePageChange = (page) => { setPage(page); };



  //아래 정리 해야함
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdDate');
  const [dense, setDense] = React.useState(false);

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (page) * items - boardList.length) : 0;

  return (
    <Box>
      <Box marginTop={{xs:12, md:8}}>
        <Grid container spacing={8} direction={isMd ? 'row' : 'column'}>
          <Box
            padding={{ xs: 3, sm: 4 }}
            // width={"100%"}
            sx = {{ width: { xs: "80%", sm: "90%", md: "100%" }  }}
            component={Card}
            boxShadow={1}
            data-aos="fade-up"
            justifyContent='flex-end'
            marginLeft={{ xs: 8, md: 10 }}
            marginRight={{ xs: 4 ,md: 2 }}

          >
            <form noValidate autoComplete="off">
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} >
                <Box
                  width={1}
                  marginRight={{ xs: 0, md: 2 }}
                  marginBottom={{ xs: 2, md: 0 }}
                >
                  <TextField
                    sx={{
                      height: 54,
                    }}
                    key={titleSearch}
                    onChange={titleSearchChane}
                    variant="outlined"
                    color="secondary"
                    size="medium"
                    fullWidth
                    placeholder="제목검색…"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                            color={'primary.main'}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box>
                  <Button
                    sx={{ height: 54, whiteSpace: 'nowrap'}}
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    onClick={titleSearchClick}
                  >
                    검색
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Grid>
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginTop={2} marginRight={2} >

          <Button variant="contained" color="success"  href="/edu/eduPlan/insert">
            게시글 작성
          </Button>
        </Box>

      </Box>
      {/*게시판목록*/}

      <Box marginTop={{ xs: 1, md: 2 }} marginLeft={{ xs: 1, md: 2 }}>
        <Paper sx={{ width: '98%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                rowCount={boardList.length}
              />
              <TableBody>
                {boardList.slice(
                  items * (page - 1),
                  items * (page - 1) + items,
                ).map((v, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      component={Link}
                      to={`/edu/eduPlan/view`}
                      state={{ boardId: v.id }}
                      // onClick={(event) => handleClick(event, v.id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={v.id}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        {/*<Checkbox*/}
                        {/*  color="primary"*/}
                        {/*  checked={isItemSelected}*/}
                        {/*  inputProps={{*/}
                        {/*    'aria-labelledby': labelId,*/}
                        {/*  }}*/}
                        {/*/>*/}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {v.title}
                      </TableCell>
                      <TableCell align="right">{v.author}</TableCell>
                      <TableCell align="right">{v.views}</TableCell>
                      <TableCell align="center">{v.createdDate}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <div style={{ width: '100%' }}>
          <Box alignContent={'center'}>
            <PaginationBox>
              <Pagination
                activePage={page}
                itemsCountPerPage={items}
                totalItemsCount={boardList.length - 1}
                onChange={handlePageChange}>
              </Pagination>
            </PaginationBox>
          </Box>
        </div>


        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </Box>
  );
};
const PaginationBox = styled.div`
    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    ul.pagination li {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 1px solid #e2e2e2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
    }

    ul.pagination li:first-child {
        border-radius: 5px 0 0 5px;
    }

    ul.pagination li:last-child {
        border-radius: 0 5px 5px 0;
    }

    ul.pagination li a { text-decoration: none; color: #000080; font-size: 1rem; }
  ul.pagination li.active a { color: white; }
  ul.pagination li.active { background-color: #DAA520; }
  ul.pagination li a:hover,
  ul.pagination li a.active { color: blue; }
  `
export default BoardList;
