import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import {
  Search,
} from './components';
import picture from '../../img/eduGoal/교목.png';
import kakaoLogin from '../../components/img/kakao_login_medium_narrow.png';

const BlogReachView = () => {
  const theme = useTheme();
  return (
    <Main>
      <Box sx={{bgcolor:'#EEE8AA'}}>
        {/*<Container paddingTop={'0 !important'}>*/}
        {/*  <Search />*/}
        {/*</Container>*/}
        <Box
             alignItems={'center'}
             component={'img'}
             src={picture}
             height="1000px"
             sx={{width:'100%'}}
        >
        </Box>
      </Box>
    </Main>
  );
};

export default BlogReachView;
