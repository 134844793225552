// src/App.js
import "../css/editor.css"
import React,{ useEffect, useMemo, useRef, useState} from "react"
import "react-quill/dist/quill.snow.css"
import ReactQuill from "react-quill"
import axios from "axios";
import DOMPurify from 'dompurify';
import Quill from 'quill';
import {ImageActions} from "@xeger/quill-image-actions";
import {ImageFormats} from "@xeger/quill-image-formats";
import { ImageDrop } from "quill-image-drop-module";
import Dropzone, {useDropzone} from "react-dropzone";
import AWS from 'aws-sdk';


Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);
Quill.register("modules/imageDrop", ImageDrop);

function QuillView() {

    const [content, setContent] = useState("")

    const [quillValue, setQuillVaule] = React.useState();
    const [quillResult, setQuillResult] = React.useState();
    const imageInputREF = React.useRef();
    const QuillREF = React.useRef();
    const [quillImage, setQuillImage] = React.useState([]);
    const [quillImagebase, setQuillImagebase] = React.useState([]);

    const quillRef = useRef();

    const [files, setFiles] = useState([]);

    AWS.config.update({
        region: "ap-northeast-2", // 버킷이 존재하는 리전을 문자열로 입력합니다. (Ex. "ap-northeast-2")
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.AWS_POOL_ID, // cognito 인증 풀에서 받아온 키를 문자열로 입력합니다. (Ex. "ap-northeast-2...")
        }),
    });

    const handleFileChange = (e) => {
        setFiles(Array.from(e.target.files));
    }


    // 이미지 처리를 하는 핸들러
    const imageHandler = () => {
        console.log('에디터에서 이미지 버튼을 클릭하면 이 핸들러가 시작됩니다!');
        //이미지 파일을 첨부할 수 있는 input을 만든다.
        // 1. 이미지를 저장할 input type=file DOM을 만든다.
        const input = document.createElement('input');
        // 속성 써주기
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        //다중선택
        input.setAttribute('multiple','');
        input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.


        //input에 이벤트리스너를 붙여, change 이벤트가 발생할 경우 2번째 인자인 함수를 실행시킨다.
        // input에 변화가 생긴다면 = 이미지를 선택
        input.addEventListener('change', async () => {
            console.log('온체인지');
            console.log(input.files)

            // multer에 맞는 형식으로 데이터 만들어준다.
            //서버에서 FormData형식으로 받기 때문에 이에 맞는 데이터형식으로 만들어준다.
            const formData = new FormData();

            for(const file of input.files){
                console.log(file)
                formData.append('img',file); //formData는 키-밸류 구조
            }
            // 백엔드 multer라우터에 이미지를 보낸다.
            try {
                const result = await axios.post('/api/imgList', formData);
                console.log('성공 시, 백엔드가 보내주는 데이터', result.data.url);

                for (const IMG of result.data) {
                    const IMG_URL = IMG.url;
                    console.log(IMG_URL)
                    // 이 URL을 img 태그의 src에 넣은 요소를 현재 에디터의 커서에 넣어주면 에디터 내에서 이미지가 나타난다
                    // src가 base64가 아닌 짧은 URL이기 때문에 데이터베이스에 에디터의 전체 글 내용을 저장할 수있게된다
                    // 이미지는 꼭 로컬 백엔드 uploads 폴더가 아닌 다른 곳에 저장해 URL로 사용하면된다.

                    // 이미지 태그를 에디터에 써주기 - 여러 방법이 있다.
                    const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
                    // 1. 에디터 root의 innerHTML을 수정해주기
                    // editor의 root는 에디터 컨텐츠들이 담겨있다. 거기에 img태그를 추가해준다.
                    // 이미지를 업로드하면 -> 멀터에서 이미지 경로 URL을 받아와 -> 이미지 요소로 만들어 에디터 안에 넣어준다.
                    // editor.root.innerHTML =
                    //   editor.root.innerHTML + `<img src=${IMG_URL} /><br/>`; // 현재 있는 내용들 뒤에 써줘야한다.

                    // 2. 현재 에디터 커서 위치값을 가져온다
                    const range = editor.getSelection();
                    // 가져온 위치에 이미지를 삽입한다
                    editor.insertEmbed(range.index, 'image', IMG_URL);

                    // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동
                    editor.setSelection(range.index + 1);
                }

            } catch (error) {
                console.log('실패했어요ㅠ');
            }
        });
        }

    const insertButton = async () => {

        const insertData = new FormData(); // formData는 키-밸류 구조
        insertData.append('saveValue', quillValue); //quill 에디터 결과

        files.map((file) => {
            insertData.append("files", file);//첨부파일
        });

        const result = await axios.post('/api/insertQuill', insertData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(result)
    }


    // 옵션에 상응하는 포맷, 추가해주지 않으면 text editor에 적용된 스타일을 볼수 없음
    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "align",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "background",
        "color",
        "link",
        "image",
        "video",
        'height',
        "width",
        "float",
        "table",
    ];

    // quill에서 사용할 모듈
    // useMemo를 사용하여 modules가 렌더링 시 에디터가 사라지는 버그를 방지
    const modules = useMemo(() => {
        return {
            toolbar: {
                container:
                    [
                        // [{ 'font': [] }],    //웹사이즈 기본폰트를 사용하기위해 제거
                        [{header: "1"}, {header: "2"}, {'header': [1, 2, 3, 4, false]}], ['bold', 'italic', 'underline', 'strike'],
                        [{'color': []}, {'background': []}],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                        [{'indent': '-1'}, {'indent': '+1'}],
                        [{'align': []}],
                        ['link', 'image'],
                    ],
                    handlers: {
                        image : imageHandler
                        // mycustom : videoHandler
                    },
            },
                    imageActions: {},
                    imageFormats: {},
                    // imageDrop: true,

        }
    }, [])
    return (

        <div style={{margin: "50px"}}>
            <div>
                <input
                    className='file-input'
                    type="file"
                    multiple="multiple"
                    onChange={handleFileChange}
                />
            </div>
            <label htmlFor="title">제목</label>
            {/*<input id="title" type="text" onChange={handleTitleChange}/>*/}
            {/*<button onClick={() => console.log(content)}>Value</button>*/}
            <button onClick={insertButton}>저장하기</button>
            <button onClick={() => console.log(quillValue)}>Value</button>
            <ReactQuill
                style={{width: "600px", height: "600px"}}
                placeholder="Quill Content"
                value={quillValue}
                ref={quillRef}
                className="form-control text-editor"
                theme='snow'
                modules={modules}
                formats={formats}
                onChange={(e)=>{setQuillVaule(e);}}

            />

            <div
                style={{
                    marginTop: '30px',
                    overflow: 'hidden',
                    whiteSpace: 'pre-wrap',
                }}></div>
        </div>
    )


}

export default QuillView;