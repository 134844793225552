export { default as Home } from './Home';
export { default as SchoolIntro } from './SchoolIntro';
export { default as Intro } from './Intro';
export { default as SchoolEnv } from './SchoolEnv';
export { default as EduProgram } from './EduProgram';
export { default as EduGoal } from './EduGoal';
export { default as ComeMap } from './ComeMap';
export { default as KAKAO } from './KAKAO';
export { default as MyPage } from './MyPage';
export { default as ChangeGrade } from './ChangeGrade';
