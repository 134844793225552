import React from 'react';

import {
  Word, WordInsert, WordUpdate,
  WordView,
  Notice, NoticeInsert, NoticeUpdate,
  NoticeView,
  Menu, MenuInsert, MenuUpdate,
  MenuView,
  Letter, LetterInsert, LetterUpdate,
  LetterView,
  Form, FormInsert, FormUpdate,
  FormView,
} from 'news';
const routes = [
  //어린이집 소식
  {
    path: '/news/word/word',
    renderer: (params = {}) => <Word {...params} />,
  },
  {
    path: '/news/word/view',
    renderer: (params = {}) => <WordView {...params} />,
  },
  {
    path: '/news/word/update',
    renderer: (params = {}) => <WordUpdate {...params} />,
  },
  {
    path: '/news/word/insert',
    renderer: (params = {}) => <WordInsert {...params} />,
  },
  //공지사항
  {
    path: '/news/notice/notice',
    renderer: (params = {}) => <Notice {...params} />,
  },
  {
    path: '/news/notice/view',
    renderer: (params = {}) => <NoticeView {...params} />,
  },
  {
    path: '/news/notice/update',
    renderer: (params = {}) => <NoticeUpdate {...params} />,
  },
  {
    path: '/news/notice/insert',
    renderer: (params = {}) => <NoticeInsert {...params} />,
  },
  //식단표
  {
    path: '/news/menu/menu',
    renderer: (params = {}) => <Menu {...params} />,
  },
  {
    path: '/news/menu/view',
    renderer: (params = {}) => <MenuView {...params} />,
  },
  {
    path: '/news/menu/update',
    renderer: (params = {}) => <MenuUpdate {...params} />,
  },
  {
    path: '/news/menu/insert',
    renderer: (params = {}) => <MenuInsert {...params} />,
  },
  //가정통신문
  {
    path: '/news/letter/letter',
    renderer: (params = {}) => <Letter {...params} />,
  },
  {
    path: '/news/letter/view',
    renderer: (params = {}) => <LetterView {...params} />,
  },
  {
    path: '/news/letter/update',
    renderer: (params = {}) => <LetterUpdate {...params} />,
  },
  {
    path: '/news/letter/insert',
    renderer: (params = {}) => <LetterInsert {...params} />,
  },
  //서식 다운로드
  {
    path: '/news/form/form',
    renderer: (params = {}) => <Form {...params} />,
  },
  {
    path: '/news/form/view',
    renderer: (params = {}) => <FormView {...params} />,
  },
  {
    path: '/news/form/update',
    renderer: (params = {}) => <FormUpdate {...params} />,
  },
  {
    path: '/news/form/insert',
    renderer: (params = {}) => <FormInsert {...params} />,
  },
];

export default routes;
