// src/App.js
import "../../../css/editor.css"
import React,{ useEffect, useMemo, useRef, useState} from "react"
import "react-quill/dist/quill.snow.css"
import ReactQuill from "react-quill"
import axios from "axios";
import Quill from 'quill';
import {ImageActions} from "@xeger/quill-image-actions";
import {ImageFormats} from "@xeger/quill-image-formats";
import { ImageDrop } from "quill-image-drop-module";
import AWS from 'aws-sdk';

Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);
Quill.register("modules/imageDrop", ImageDrop);

function QuillView() {
    const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
    const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));
    const [quillValue, setQuillVaule] = React.useState();
    const quillRef = useRef();
    const mainTitle = useRef();    // 타이틀

    const [files, setFiles] = useState([]);//첨부파일 부분
    let uploadFile = new Array();

    const [Selected, setSelected] = useState("garden");


    AWS.config.update({
        region: "ap-northeast-2", // 버킷이 존재하는 리전을 문자열로 입력합니다. (Ex. "ap-northeast-2")
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.AWS_POOL_ID, // cognito 인증 풀에서 받아온 키를 문자열로 입력합니다. (Ex. "ap-northeast-2...")
        }),
    });


    useEffect(() => {
        checkTokenExpire(); // 1) 게시글 목록 조회 함수 호출
    }, []);

    //만료시간 체크
    const checkTokenExpire = () => {
        const parseTokenExpiresIn = tokenExpiresIn.expireTime;
        if (!parseTokenExpiresIn) {
            
            return null;
        }

        const now = new Date();

        if (now.getTime() > parseTokenExpiresIn) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('tokenExpiresIn');
            
            return null
        }
        
        return parseTokenExpiresIn
    }

    const handleFileChange = (e) => {
        const input = document.createElement('input');
        // addFileInput.current.click();
        // 속성 써주기
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        //다중선택
        input.setAttribute('multiple', '');
        input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
        input.addEventListener('change', async () => {
            for (const file of input.files) {
                
                setFiles(files => [...files, file]);//첨부파일
            }
        })
    }

    const deleteAddFile = (name) => {
        // files에서 클릭한 버튼 제거
        setFiles(files.filter(file => file.name !== name));
    }

    const saveMainTitle = (e) => {
        mainTitle.current = e.target.value
    }

    // 이미지 처리를 하는 핸들러
    const imageHandler = () => {
        //이미지 파일을 첨부할 수 있는 input을 만든다.
        // 1. 이미지를 저장할 input type=file DOM을 만든다.
        const input = document.createElement('input');
        // 속성 써주기
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        //다중선택
        input.setAttribute('multiple', '');
        input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.


        //input에 이벤트리스너를 붙여, change 이벤트가 발생할 경우 2번째 인자인 함수를 실행시킨다.
        // input에 변화가 생긴다면 = 이미지를 선택
        input.addEventListener('change', async () => {

            // multer에 맞는 형식으로 데이터 만들어준다.
            //서버에서 FormData형식으로 받기 때문에 이에 맞는 데이터형식으로 만들어준다.
            const formData = new FormData();
            formData.append('dir','police/')
            for (const file of input.files) {
                formData.append('img', file); //formData는 키-밸류 구조
            }
            // 백엔드 multer라우터에 이미지를 보낸다.
            try {
                const result = await axios.post('/api/imgList', formData,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    });

                for (const IMG of result.data) {
                    const IMG_URL = IMG.url;
                    // 이 URL을 img 태그의 src에 넣은 요소를 현재 에디터의 커서에 넣어주면 에디터 내에서 이미지가 나타난다
                    // src가 base64가 아닌 짧은 URL이기 때문에 데이터베이스에 에디터의 전체 글 내용을 저장할 수있게된다
                    // 이미지는 꼭 로컬 백엔드 uploads 폴더가 아닌 다른 곳에 저장해 URL로 사용하면된다.

                    // 이미지 태그를 에디터에 써주기 - 여러 방법이 있다.
                    const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
                    // 1. 에디터 root의 innerHTML을 수정해주기
                    // editor의 root는 에디터 컨텐츠들이 담겨있다. 거기에 img태그를 추가해준다.
                    // 이미지를 업로드하면 -> 멀터에서 이미지 경로 URL을 받아와 -> 이미지 요소로 만들어 에디터 안에 넣어준다.
                    // editor.root.innerHTML =
                    //   editor.root.innerHTML + `<img src=${IMG_URL} /><br/>`; // 현재 있는 내용들 뒤에 써줘야한다.

                    // 2. 현재 에디터 커서 위치값을 가져온다
                    const range = editor.getSelection();
                    // 가져온 위치에 이미지를 삽입한다
                    editor.insertEmbed(range.index, 'image', IMG_URL);

                    // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동
                    editor.setSelection(range.index + 1);
                }

            } catch (error) {
                alert(error.response.data.resultMsg);
                window.location.href = "/story/program/garden"
            }
        });
    }

    const insertButton = async () => {
        if(!mainTitle.current){
            alert("제목을 입력해주세요");
        }else{
        //마지막 결정된 첨부파일 먼저 aws 다녀와서 url받아둬야 한다.
        try {
            if (files.length > 0) {
                const formData = new FormData();
                formData.append('dir','police/')
                for (const file of files) {
                    formData.append('img', file); //formData는 키-밸류 구조
                }
                // 백엔드 multer라우터에 이미지를 보낸다.
                try {
                    const result = await axios.post('/api/imgList', formData,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        });

                    for (const file of result.data) {
                        // setDbFiles(dbFiles => [...dbFiles, file]);//첨부파일
                        uploadFile.push(file);//addFile DB에 추가할 첨부파일 목록
                    }
                } catch (error) {
                    alert(error.response.data.resultMsg);
                    window.location.reload();
                }
            }

            try {//백엔드에 데이터 보내기
                let data = {
                    userId: tokenExpiresIn.userId,
                    author: tokenExpiresIn.nickName,
                    views: 0,
                    content: quillValue,
                    title: mainTitle.current,
                    boardList: Selected,
                    uploadFileDtoList: uploadFile,
                }
                const result = await axios.post('/api/insert/program', JSON.stringify(data), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    }
                });
                alert('등록되었습니다.');
                window.location.href = "/story/program/garden"
            } catch (error) {
                alert(error.response.data.resultMsg);
                // window.location.reload();
                window.location.href = "/story/program/garden"
            }
        }catch (error){}}
    }


    const fileName = (files) => {
        const result = [];
        for (let i = 0; i < files.length; i++) {
            result.push(<div key={i}>
                <button onClick={() => deleteAddFile(files[i].name,files[i].filePath)}>{files[i].name}</button>
            </div>);
        }
        return result;
    };

    const selectBox = () => {
        return (
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                게시판 목록
                            </td>
                            <td>
                                <select onChange={handleSelect} value={Selected}>
                                    <option key="garden" value="garden">텃밭체험</option>
                                    <option key="forest" value="forest">숲 산책</option>
                                    <option key="music" value="music">음악 프로그램</option>
                                    <option key="happy" value="happy">행복 프로그램</option>
                                    <option key="community" value="community">어울림 프로그램</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const handleSelect = (e) => {
        setSelected(e.target.value);
    };

    // 옵션에 상응하는 포맷, 추가해주지 않으면 text editor에 적용된 스타일을 볼수 없음
    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "align",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "background",
        "color",
        "link",
        "image",
        "video",
        'height',
        "width",
        "float",
        "table",
    ];

    // quill에서 사용할 모듈
    // useMemo를 사용하여 modules가 렌더링 시 에디터가 사라지는 버그를 방지
    const modules = useMemo(() => {
        return {
            toolbar: {
                container:
                    [
                        // [{ 'font': [] }],    //웹사이즈 기본폰트를 사용하기위해 제거
                        [{header: "1"}, {header: "2"}, {'header': [1, 2, 3, 4, false]}], ['bold', 'italic', 'underline', 'strike'],
                        [{'color': []}, {'background': []}],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                        [{'indent': '-1'}, {'indent': '+1'}],
                        [{'align': []}],
                        ['link', 'image'],
                    ],
                handlers: {
                    image: imageHandler
                    // mycustom : videoHandler
                },
            },
            imageActions: {},
            imageFormats: {},
            // imageDrop: true,

        }
    }, [])
    return (


        <div style={{margin: "50px"}}>
            <div>
                <button onClick={handleFileChange}>첨부파일 업로드하기</button>
            </div>
            <div>
                    {fileName(files)}
            </div>
                    {selectBox()}
            <div>
                제목
                <input type='text' key={mainTitle} onChange={saveMainTitle} placeholder="제목을 입력하세요"></input>
            </div>
            <label htmlFor="title">제목2</label>
            {/*<input id="title" type="text" onChange={handleTitleChange}/>*/
            }
            {/*<button onClick={() => console.log(content)}>Value</button>*/
            }
            <button onClick={insertButton}>저장하기</button>
            <button onClick={() => console.log(quillValue)}>Value</button>
            <ReactQuill
                style={{width: "600px", height: "600px"}}
                placeholder="Quill Content"
                value={quillValue}
                ref={quillRef}
                className="form-control text-editor"
                theme='snow'
                modules={modules}
                formats={formats}
                onChange={(e) => {
                    setQuillVaule(e);
                }}

            />

            <div
                style={{
                    marginTop: '30px',
                    overflow: 'hidden',
                    whiteSpace: 'pre-wrap',
                }}></div>
        </div>
    )


}

export default QuillView;