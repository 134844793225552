import Router from "../../router/Router"
import "../../css/main.css"
import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";

function Main() {
    return (
        <div className="py-4">
            <div className="container">
                {ChangeNickName()}
            </div>
        </div>
    );
}

const ChangeNickName = () => {
    const [boardList, setBoardList] = useState([]);
    const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
    const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));
    // const [nickName, setNickName] = useState(null);    // 타이틀
    const nickName = useRef(null);
    const saveNickName = (e) => {
        // setNickName(e.target.value)
        nickName.current = e.target.value
    }

    const insertButton = async () => {


                try {//백엔드에 데이터 보내기
                    let data = {
                        id : tokenExpiresIn.userId,
                        nickName : nickName.current,
                    }

                    const result = await axios.post('/api/nickName',  JSON.stringify(data), {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });
                    console.log('성공 시, 백엔드가 보내주는 데이터', result);
                    var newTokenExpiresIn = JSON.parse(localStorage.getItem('tokenExpiresIn'));
                    newTokenExpiresIn.nickName = result.data.nickName;
                    // localStorage.removeItem("tokenExpiresIn");
                    localStorage.setItem("tokenExpiresIn",JSON.stringify(newTokenExpiresIn));

                    alert('변경되었습니다.');
                    window.location.href = "/"
                } catch (error) {
                    console.log('실패했어요ㅠ');
                    alert(error.response.data.resultMsg);
                    // window.location.reload();
                    window.location.href = "/"
                }
    }

    const  checkTokenExpire = () =>{
        const parseTokenExpiresIn = tokenExpiresIn;
        if(!parseTokenExpiresIn){
            
            return null;
        }

        const now = new Date();

        if (now.getTime()>parseTokenExpiresIn){
            localStorage.removeItem('accessToken');
            localStorage.removeItem('tokenExpiresIn');
            
            return null
        }
        
        return parseTokenExpiresIn
    }



    useEffect(() => {
        checkTokenExpire(); // 1) 게시글 목록 조회 함수 호출
    }, []);

    return (
        <div>
            <button><Link to={"/changeNickName"}>닉네임 변경</Link></button>
            <button><Link to={"/changeGrade"}>권한 변경</Link></button>
            <div>현재 닉네임 : {tokenExpiresIn.nickName}</div>
            <div>
                닉네임을 변경
                <input type='text' key={nickName} onChange={saveNickName} placeholder="ex)24년생 이유준엄마"/>
            </div>
            <button onClick={insertButton}>저장하기</button>
        </div>
    );
};

export default Main;