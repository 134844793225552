import React from 'react';

import {Insert} from './components';
const DisProgramInsert = () => {
  return (
          <Insert />
  );
};

export default DisProgramInsert;
