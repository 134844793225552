import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import bgImg from '../../../../../img/글쓰기배경2.jpg'
import Container from 'components/Container';

const pages = [
  {
    id: 'activity',
    href: '/story/activity/activity',
    title: '특별활동',
  },

];

const Page = ({ children }) => {
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          backgroundImage:`url(${bgImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        marginRight={{ xs :3, md : 6 }}
           paddingY={4}>
        <Container>
          <Typography
            variant="h2"
            fontWeight={700}
            gutterBottom
            sx={{ color: '#1a75ff',
              fontFamily: '"Noto Sans KR", sans-serif',
            }}
            marginLeft={{ xs: 2, md: 4 }}
          >
            오시는 길
          </Typography>
        </Container>
      </Box>
      <Container paddingTop={'0 !important'} marginTop={-8}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Card sx={{ boxShadow: 3, padding: 4 }}>{children}</Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
