import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Page from '../components/Page';
import Main from 'layouts/Main';
import axios from "axios";
import {Link, useLocation} from 'react-router-dom';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Pagination from 'react-js-pagination';
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
const View = () => {


  const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
  const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));
  const [detailView,setDetailView] = useState({id: null,title: null, author: null, views: null,boardList:null, content: null, viewFileDtoList:[],commentList:[]});
  let location = useLocation();
  const boardId = location.state.boardId;
  const comment = useRef("");

//페이징 처리
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(5);
  const handlePageChange = (page) => { setPage(page); };

  const getBoardView = async () => {
    try {
      const resp = await (await axios.get("/api/news/form/view", {
        params:{id:boardId,
                tableName:'form',
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      setDetailView(resp.data); // 3) boardList 변수에 할당
    } catch (error) {
      alert(error.response.data.resultMsg);
      window.location.href = "/"
    }
  }
  const fileName = () => {
    const result = [];
    for (let i = 0; i < detailView.viewFileDtoList.length; i++) {
      result.push(<div key={i}>
        <Chip label={detailView.viewFileDtoList[i].name} variant="outlined" onClick={() => downloadFile(detailView.viewFileDtoList[i].url,detailView.viewFileDtoList[i].name)} />
        {/*<button onClick={() => downloadFile(detailView.viewFileDtoList[i].url,detailView.viewFileDtoList[i].name)}>{detailView.viewFileDtoList[i].name}</button>*/}
      </div>);
    }
    return result;
  };

  const downloadFile=async (url,fileName) =>{

    fetch(url, {
      method: "GET",
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);

        const aTag = document.createElement("a");

        aTag.href = blobURL;
        aTag.download = fileName;

        aTag.click();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const CommentSet = (e) => {
    comment.current = e.target.value
  }

  const deleteComment = async (commentId) =>{
    try {
    const result = await (await axios.get('/api/news/deleteComment/form',  {
      params:{id:commentId},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }));
    alert('삭제되었습니다.');
    window.location.reload();
  }catch (error){
    alert(error.response.data.resultMsg);
    window.location.reload();
  }
  }
  const saveComment = async() =>{
    try {
      let data = {
        content: comment.current,
        boardId: boardId,
        userId: tokenExpiresIn.userId,
        writer: tokenExpiresIn.nickName,
        boardList: detailView.boardList,
      }
      const result = await axios.post('/api/news/saveComment/form', JSON.stringify(data), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      alert('등록되었습니다.');
      window.location.reload();
    }catch (error){
      alert(error.response.data.resultMsg);
      window.location.reload();
    }
  }

  const updateBtn = () =>{
    if(detailView.userId ==tokenExpiresIn.userId){
      return(<Button><Link to={'/news/form/update'} state={{boardId:detailView.id}}>수정</Link></Button>);
    }
    return null;
  }
  const deleteBtn = () =>{
    if(detailView.userId ==tokenExpiresIn.userId){
      return(<Button type="button" onClick={deleteBoard}>삭제</Button>);
    }
    return null;
  }

  const deleteCommentBtn = (v) =>{
    if(detailView.userId ==tokenExpiresIn.userId || tokenExpiresIn.userId == v.userId){
      return (
        <Grid container item xs={12} md={12} spacing={2} marginTop={1}>
        <Grid item alignItems={'center'} xs={8} md={10}>
          <Typography variant={'subtitle2'}>
            {v.writer}   {v.createdDate}
            <br />
            {v.content}
            <Divider />
          </Typography>
        </Grid>

        <Grid item alignItems={'center'} xs={4} md={2} sx={{ justifyContent: 'flex-end' }}>
          <IconButton aria-label="delete" size="small" key={v.id} onClick={() =>deleteComment(v.id)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
        </Grid>
      );
    }
    return (
      <Grid container item xs={12} md={12} spacing={2} marginTop={1} marginLeft={1}>
        <Grid item alignItems={'center'} xs={12} md={12}>
          <Typography variant={'subtitle2'}>
            {v.writer} , {v.createdDate}
            <br />
            {v.content}
            <Divider />
          </Typography>
        </Grid>
      </Grid>
    );
  }
  const deleteBoard = async() =>{
    try {
      const result =await (await axios.get('/api/news/delete/form', {
        params:{id:boardId,
          tableName:'form',
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }));
      alert('삭제되었습니다.');
      window.location.href = "/news/form/form";
    }catch (error){
      alert(error.response.data.resultMsg);
      window.location.reload();
    }
  }

  const  checkTokenExpire = () => {
    const parseTokenExpiresIn = tokenExpiresIn;
    if(!parseTokenExpiresIn){
      
      return null;
    }

    const now = new Date();

    if (now.getTime()>parseTokenExpiresIn){
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tokenExpiresIn');
      
      return null
    }
    
    return parseTokenExpiresIn
  }


  useEffect(() => {
    getBoardView(); // 1) 게시글 상세정보 함수 호출
  }, []);

  useEffect(() => {
    updateBtn(); // 1) 게시글 목록 조회 함수 호출
    fileName();
  }, [detailView]);

  useEffect(() => {
    checkTokenExpire(); // 권한체크
  }, []);

  return (
    <Main>
      <Page>
        <Box>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            {detailView.title}
          </Typography>
          <Grid container  spacing={2}>
          <Grid item alignItems={'center'} xs={8} md={10}>
            <Typography variant="subtitle2" gutterBottom fontWeight={500}>
           {detailView.author}  {detailView.createdDate} / 조회수: {detailView.views}
            </Typography>
          </Grid>
          <Grid item alignItems={'center'}xs={4} md={2}>
          <ButtonGroup size="small" color="secondary"  aria-label="Small button group"
                       sx={{
                         display: 'flex',
                         justifyContent: 'flex-end',

                         bgcolor: 'background.paper',
                         borderRadius: 1,
                       }}
          >
              {updateBtn()}
              {deleteBtn()}
          </ButtonGroup>
          </Grid>
          </Grid>
          <Stack direction="row" spacing={1} marginTop={1}>
            {fileName()}
          </Stack>
          <Box paddingY={4}>
            <Divider />
          </Box>

          <form >
            <Grid container spacing={4}>

              <Grid item xs={12} md={12}>

                <Typography
                  style={{
                    border: '2px solid black',
                    overflow: 'hidden',
                    overflowX: 'scroll', // added scroll
                  }}
                  // style={{ width: '80%' }}
                  width={'auto'}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html:
                    detailView.content,
                  }}
                >
                </Typography>
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <Divider />
              </Grid>
            </Grid>

            {/*댓글시작*/}

        <Grid container item xs={12} md={12} spacing={1} marginTop={1}>
          <Grid item alignItems={'center'} xs={8} md={10.5}>
            <TextField
              label="댓글을 입력하세요.."
                  variant="outlined"
                  name={'bio'}
                  multiline
                  // rows={5}
                  fullWidth
                  key={comment}
                  onChange={CommentSet}
                />
              </Grid>
              <Grid item alignItems={'center'} xs={4} md={1.5} marginTop={0.5}>
                <Button size={'large'} variant={'contained'} type={'button'} onClick={saveComment}>
                  등록
                </Button>
              </Grid>


              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ xs: 'stretched', sm: 'center' }}
                  justifyContent={'space-between'}
                  width={1}
                  margin={'0 auto'}
                >
                  <Box>
                    {
                      detailView.commentList.slice(
                        10 * (page - 1),
                        10 * (page - 1) + 10,
                      ).map((v, i) => {
                        return (
                          //댓글표시
                            deleteCommentBtn(v)
                        );
                      })
                    }
                  </Box>
                </Box>
              </Grid>

              <div style={{ width: '100%' }}>
                <Box alignContent={'center'}>
                  <PaginationBox>
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={items}
                      totalItemsCount={detailView.commentList.length - 1}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}>
                    </Pagination>
                  </PaginationBox>
                </Box>
              </div>


          </Grid>

        </form>
      </Box>
    </Page>
    </Main>
  );
};
const PaginationBox = styled.div`
  .pagination { display: flex; justify-content: center; margin-top: 15px;}
  ul { list-style: none; padding: 0; }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem; 
  }
  ul.pagination li:first-child{ border-radius: 5px 0 0 5px; }
  ul.pagination li:last-child{ border-radius: 0 5px 5px 0; }
  ul.pagination li a { text-decoration: none; color: #337ab7; font-size: 1rem; }
  ul.pagination li.active a { color: white; }
  ul.pagination li.active { background-color: #337ab7; }
  ul.pagination li a:hover,
  ul.pagination li a.active { color: blue; }
  `
export default View;
