import React from 'react';

import {View} from './components';
const MenuView = () => {
  return (
          <View />
  );
};

export default MenuView;
