import React from 'react';

import {Insert} from './components';
const FormInsert = () => {
  return (
          <Insert />
  );
};

export default FormInsert;
