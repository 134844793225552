export { default as DisProgram } from './DisProgram';
export { default as DisProgramView } from './DisProgramView';
export { default as DisProgramUpdate } from './DisProgramUpdate';
export { default as DisProgramInsert } from './DisProgramInsert';

export { default as EduPlan } from './EduPlan';
export { default as EduPlanView } from './EduPlanView';
export { default as EduPlanUpdate } from './EduPlanUpdate';
export { default as EduPlanInsert } from './EduPlanInsert';

export { default as Teacher } from './Teacher';
export { default as TeacherView } from './TeacherView';
export { default as TeacherUpdate } from './TeacherUpdate';
export { default as TeacherInsert } from './TeacherInsert';
