import React from 'react';

import {
  Rank, RankInsert , RankUpdate, RankView,

} from 'rank';
const routes = [
  {
    path: '/rank/rank/rank',
    renderer: (params = {}) => <Rank {...params} />,
  },
  {
    path: '/rank/rank/view',
    renderer: (params = {}) => <RankView {...params} />,
  },
  {
    path: '/rank/rank/update',
    renderer: (params = {}) => <RankUpdate {...params} />,
  },
  {
    path: '/rank/rank/insert',
    renderer: (params = {}) => <RankInsert {...params} />,
  },
];

export default routes;
