import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Pic1 from 'img/flactionImg/free-icon-lifestyle-4939195.png'
import Pic2 from 'img/flactionImg/free-icon-exercise-4072281.png'
import Pic3 from 'img/flactionImg/free-icon-best-friend-4126401.png'
import Pic4 from 'img/flactionImg/free-icon-folk-art-12431537.png'
import Pic5 from 'img/flactionImg/free-icon-communication-5829542.png'
import Pic6 from 'img/flactionImg/free-icon-philosophy-4576683.png'

import Pic7 from 'img/flactionImg/free-icon-medal-1397176.png'
import Pic8 from 'img/flactionImg/free-icon-family-4547238.png'
import Pic9 from 'img/flactionImg/free-icon-seminar-1478942.png'
import Pic10 from 'img/flactionImg/free-icon-philosophy-4576683.png'
import Pic11 from 'img/flactionImg/free-icon-lamp-2910890.png'

const edu = [
  {
    title: '원훈',
    subtitle:
      '몸과 마음이 건강한 어린이 / 꿈과 사랑을 키우는 어린이 / 배려하고 존중하는 어린이',
    avatar: Pic7,
  },
  {
    title: '부모상',
    subtitle:
      '참여하는 부모 / 아이생각을 인정하는 부모 / 교직원을 신뢰하는 부모',
    avatar: Pic8,
  },
  {
    title: '교사상',
    subtitle:
      '어린이를 사랑하는 교사 / 아이들의 눈 높이를 맞추는 교사 / 책임감 있고 연구하는 교사',
    avatar: Pic9,
  },
  {
    title: '교육철학',
    subtitle:
      '놀이를 통한 교육적 접근 및 실생활의 경험을 바탕으로 영•유아의 자연적 성장발달을 고려한 교육활동과 준비된 환경을 통해 자발적 참여를 독려하고 상호작용을 중시하여 주체로서의 영유아를 존중하는 아동중심 교육을 실시',
    avatar: Pic11,
  },
];

const care = [
  {
    feedback:
      '신체의 청결과 위생, 수면과 휴식, 바른 식생활 등 의식주에 대한 올바른 습관을 기른다.',
    name: '기본생활',
    title: 'MUI lover',
    avatar: Pic1,
  },
  {
    feedback:
      '자신의 신체를 긍정적으로 인식하고 즐겁게 신체활동에 참여하여 기본적인 운동능력을 기른다.',
    name: '신체운동',
    title: 'Senior Frontend Developer',
    avatar: Pic2,
  },
  {
    feedback:
      '긍정적인 자아개념을 형성하고 다른 사람과 함께 살아가는 능력을 기른다.',
    name: '사회관계',
    title: 'SEO at Comoti',
    avatar: Pic3,
  },
  {
    feedback:
      '자신의 생각과 느낌을 다양하고 창의적으로 표현하며 자유롭게 즐기는 태도를 기른다.',
    name: '예술경험',
    title: 'MUI lover',
    avatar: Pic4,
  },
  {
    feedback:
      '일상생활에 필요한 기초적인 언어능력과 바른 언어생활 습관을 기르고, 기초적인 문제 해결 능력을 기른다.',
    name: '의사소통',
    title: 'Senior Frontend Developer',
    avatar: Pic5,
  },
  {
    feedback:
      '주변현상에 관심을 갖고 탐구하는 태도와 자연을 사랑하는 마음을 가진다.',
    name: '자연탐구',
    title: 'SEO at Comoti',
    avatar: Pic6,
  },
];

const Faq = () => {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h4"
        align={'center'}
        data-aos={'fade-up'}
        gutterBottom
        sx={{
          fontWeight: 700,
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(2),
          color:'#43a047',
        }}
        style={{
          fontFamily:'"Noto Sans KR", sans-serif',
        }}
      >
        운영방침 및 현황
      </Typography>
      <Typography
        variant="h5"
        align={'center'}
        data-aos={'fade-up'}
        sx={{
          marginBottom: theme.spacing(7),
          color:'#ef6c00',
        }}
        style={{fontFamily:'"Noto Sans KR", sans-serif'}}
        marginTop={4}
      >
        교육철학
      </Typography>
      <Grid container spacing={4}>
        {edu.map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={i}
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Box display={'flex'} flexDirection={'column'}>
              <Box display={'flex'} alignItems={'center'} marginBottom={1}>
                <ListItemAvatar>
                  <Avatar src={item.avatar} />
                </ListItemAvatar>
                <Typography variant={'h6'} gutterBottom fontWeight={500}
                style={{fontFamily:'"Noto Sans KR", sans-serif'}}
                >
                  {item.title}
                </Typography>
              </Box>
              <Typography color="text.secondary" style={{fontFamily:'"Noto Sans KR", sans-serif'}}>{item.subtitle}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box marginBottom={4}>
        <Typography
          variant="h5"
          align={'center'}
          data-aos={'fade-up'}
          style={{fontFamily:'"Noto Sans KR", sans-serif'}}
          marginTop={4}
          sx={{
            color:'#0277bd',
          }}
        >
          보육목표
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {care.map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={i}
            sx={{
              border: 0,
              [theme.breakpoints.up('md')]: {
                borderRight: `1px solid ${theme.palette.alternate.dark}`,
                '&:nth-of-type(2n)': {
                  borderRight: `1px solid ${theme.palette.alternate.dark}`,
                },
                '&:nth-of-type(-n+4)': {
                  borderBottom: 0,
                },
                '&:nth-of-type(3n)': {
                  borderRight: 0,
                },
                '&:nth-of-type(-n+3)': {
                  borderBottom: `1px solid ${theme.palette.alternate.dark}`,
                },
              },
            }}
          >
            <Box p={2}>
              <Box marginBottom={2} style={{fontFamily:'"Noto Sans KR", sans-serif'}}>
                <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  <ListItemAvatar>
                    <Avatar src={item.avatar} />
                  </ListItemAvatar>
                  <Typography variant={'h6'} gutterBottom fontWeight={500}
                              style={{fontFamily:'"Noto Sans KR", sans-serif'}}
                  >
                    {item.name}
                  </Typography>
                </ListItem>
              </Box>
              <Typography color="text.secondary" style={{fontFamily:'"Noto Sans KR", sans-serif'}}>{item.feedback}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

    </Box>


  );
};

export default Faq;
