/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import picture from '../../../../img/schoolEnv/비즈니스_사진_007.JPG';
const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} xs={12} md={7}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              원장 심민서
            </Typography>
            <Typography component={'p'} sx={{ textAlign: 'center' ,  fontWeight: 'bold'}} marginTop={4} variant={'h7'}>
              안녕하십니까?
            </Typography>
            <Typography component={'p'} sx={{ textAlign: 'center' }} marginTop={1} variant={'h7'}>
              아이와 부모 그리고 선생님 모두가 행복한 곳을 꿈꾸는
              <br />
              부산경찰청 어린이집 원장 심민서 입니다.
              <br />
              <br />
              행복한 아이들의 자람터 부산경찰청 어린이집의 문이 활짝 열렸습니다.
              <br />
              영유아들에게 편안하고 아늑한 보금자리를 제공하는 부산경찰청 어린이집은
              <br />
              몸과 마음이 건강한 어린이, 신뢰하는 학부모, 연구하는 교사들 어린이집을 표방하며
              <br />
              <br />
              안전하고 쾌적한 보육환경속에서 부산경찰청의 든든한 지원과
              <br />
              킨더슐레보육경영연구소의 전문인력 및 프로그램지원을 바탕으로
              <br />
              양질의 보육서비스를 제공하는 모범적인 직장어린이집의 초석이 되겠습니다.
              <br />
              <br />
              아울러 부산경찰청의 교직원들은 하루하루 즐거운 경험과 다양한 탐색활동으로
              <br />
              아이들의 무한한 잠재력을 키워 나가며, 아이가 행복하고 부모가 자랑할 수 있는
              <br />
              즐거운 어린이집으로 자리매김 할 수 있도록 매순간 노력할 것입니다.
              <br />
              <br />
              감사합니다.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={5}
        >
          <Box maxWidth={400} width={1}>
            <Box
              component={'img'}
              src={
                picture
              }
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
