import React from 'react';

import {View} from './components';
const EduPlanView = () => {
  return (
          <View />
  );
};

export default EduPlanView;
