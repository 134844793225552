function Nav(){
    return(

        <main>
            <div className="py-4">
                <div className="container">
                    <p>여기는 Nav 입니다</p>
                    <li className="navCol-4"><a href="/write">글쓰기 테스트</a></li>
                    <nav id="nav">
                        {/*<li className="current"><a href="index.html">Home</a></li>*/}
                        <ul className="row">
                            <li className="navCol-4 current"><a href="/intro">유치원 소개</a></li>
                            <li className="navCol-4"><a href="/edu">교육 과정운영</a></li>
                            <li className="navCol-4"><a href="/story">부경 이야기</a></li>
                            <li className="navCol-4"><a href="/news">유치원 소식</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </main>
    );

}

export default Nav;