import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import bgPicture3 from '../../../../img/eduGoal/[크기변환]6114010.jpg'
const Search = () => {
  return (
    <Box sx={{backgroundImage: `url("${bgPicture3}")`}}>
      <Box>
        <Typography
          variant="h4"
          data-aos={'fade-up'}
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
            fontFamily:'"Noto Sans KR", sans-serif',
          }}
          marginTop={5}
        >
          <br/>
          원훈 및 교육목표
        </Typography>
      </Box>
      <Grid container spacing={3} >
        <Grid item xs={12} sm={6}>
          <Box
            padding={{ xs: 3, sm: 6 }}
            width={1}
            component={Card}
            boxShadow={4}
            data-aos="fade-up"
            style={{background: 'none'}}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Box>
                <Typography fontWeight={700} variant={'h6'} gutterBottom marginBottom={1} >
                  설립취지
                </Typography>
                <Typography align={'center'}  style={{fontFamily:'"Noto Sans KR", sans-serif'}}
                            fontWeight={700} variant={'h6'}>
                  부산경찰청어린이집은 여성의 사회참여와 경제활동 및 핵가족화가 급증한 현대사회에서 맞벌이 가정의 안전한 자녀양육 기능을 분담한다.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item item xs={12} sm={6}>
          <Box
            padding={{ xs: 3, sm: 6 }}
            width={1}
            component={Card}
            boxShadow={4}
            data-aos="fade-up"
            style={{background: 'none'}}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Box>
                <Typography  align={'center'}  style={{fontFamily:'"Noto Sans KR", sans-serif'}}
                            fontWeight={700} variant={'h6'} marginTop={4}>
                  교육적인 환경과 양질의 보육을 제공하여 영유아의 전인적 발달을 도모하며 가정과 기업의 보육문제를 해결하고 나아가 보육의 사회적 책임을 다하고 실천방향을 선도하는 역할을 수행한다.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} >
        <Grid item xs={12} sm={6}>
          <Box
            padding={{ xs: 3, sm: 6 }}
            width={1}
            component={Card}
            boxShadow={4}
            data-aos="fade-up"
            style={{background: 'none'}}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Box>
                <Typography fontWeight={700} variant={'h6'} gutterBottom marginBottom={3}>
                  연간운영계획의 기본방향
                </Typography>
                <Typography align={'center'}  style={{fontFamily:'"Noto Sans KR", sans-serif'}}
                            fontWeight={700} variant={'h6'}>
                  교사들의 계속적인 노력과 연구로 발달에 적합한 교육 프로그램을 제공하여 영유아들의 전인적인 발달을 촉진시켜 몸과 마음이 행복하고 건강한어린이로 성장할 수 있도록 돕는다.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            padding={{ xs: 3, sm: 6 }}
            width={1}
            component={Card}
            boxShadow={4}
            data-aos="fade-up"
            style={{background: 'none'}}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={{ xs: 'flex-stbgPictureart', sm: 'center' }}
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Box>
                <Typography marginTop={3} align={'center'} style={{ fontFamily: '"Noto Sans KR", sans-serif' }}
                            fontWeight={700} variant={'h6'}>
                  <br />
                  영유아들이 편안하게 지낼 수 있는 가정과 같은 분위기와 안전하고 쾌적한 보육환경 제공 및
                  가정과 어린이집의 한마음 교육으로 어린이와 부모, 교사가 함께 행복한 어린이집을 지향 한다.</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Typography
          variant="h4"
          data-aos={'fade-up'}
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
            fontFamily:'"Noto Sans KR", sans-serif',
          }}
          marginTop={5}
        >
          <br/>
        </Typography>
      </Box>
    </Box>
  );
};

export default Search;
