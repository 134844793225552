function Banner() {

    return (
        <main>
            <div className="py-4">
                <div className="container">
                    <p>여기는 Banner 입니다</p>

                    <section id="banner">
                        <header>
                            <h2>Arcana: <em>A responsive site template freebie by <a href="http://html5up.net">HTML5 UP</a></em></h2>
                            <a href="#" className="button">Learn More</a>
                        </header>
                    </section>

                </div>
            </div>

        </main>
    );
}

export default Banner;