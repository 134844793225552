import {Link} from "react-router-dom";
import React from "react";


function sideBar() {



    return(
        <div>
            사이드바입니다.
            <ul>
                <Link to={`/story`}>부경이야기</Link>
                    <li key={'program'}>
                        <Link to={`/story/program`}>프로그램</Link>
                    </li>

            </ul>
        </div>
    );
}


export default sideBar;