
function Footer() {
    return(
        <main>
            <div className="py-4">
                <div className="container">
                    <p>여기는 Footer입니다</p>

                    <div id="footer">
                        <div className="container">
                            <div className="row">
                                <section className="col-3 col-6-narrower col-12-mobilep">
                                    <h3>Links to Stuff</h3>
                                    <ul className="links">
                                        <li><a href="#">Mattis et quis rutrum</a></li>
                                        <li><a href="#">Suspendisse amet varius</a></li>
                                        <li><a href="#">Sed et dapibus quis</a></li>
                                        <li><a href="#">Rutrum accumsan dolor</a></li>
                                        <li><a href="#">Mattis rutrum accumsan</a></li>
                                        <li><a href="#">Suspendisse varius nibh</a></li>
                                        <li><a href="#">Sed et dapibus mattis</a></li>
                                    </ul>
                                </section>
                                <section className="col-3 col-6-narrower col-12-mobilep">
                                    <h3>More Links to Stuff</h3>
                                    <ul className="links">
                                        <li><a href="#">Duis neque nisi dapibus</a></li>
                                        <li><a href="#">Sed et dapibus quis</a></li>
                                        <li><a href="#">Rutrum accumsan sed</a></li>
                                        <li><a href="#">Mattis et sed accumsan</a></li>
                                        <li><a href="#">Duis neque nisi sed</a></li>
                                        <li><a href="#">Sed et dapibus quis</a></li>
                                        <li><a href="#">Rutrum amet varius</a></li>
                                    </ul>
                                </section>
                                <section className="col-6 col-12-narrower">
                                    <h3>Get In Touch</h3>
                                    <form>
                                        <div className="row gtr-50">
                                            <div className="col-6 col-12-mobilep">
                                                <input type="text" name="name" id="name" placeholder="Name" />
                                            </div>
                                            <div className="col-6 col-12-mobilep">
                                                <input type="email" name="email" id="email" placeholder="Email" />
                                            </div>
                                            <div className="col-12">
                                                <textarea name="message" id="message" placeholder="Message" rows="5"></textarea>
                                            </div>
                                            <div className="col-12">
                                                <ul className="actions">
                                                    <li><input type="submit" className="button alt" value="Send Message" /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </form>
                                </section>
                            </div>
                        </div>


                        <ul className="icons">
                            <li><a href="#" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
                            <li><a href="#" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
                            <li><a href="#" className="icon brands fa-github"><span className="label">GitHub</span></a></li>
                            <li><a href="#" className="icon brands fa-linkedin-in"><span className="label">LinkedIn</span></a></li>
                            <li><a href="#" className="icon brands fa-google-plus-g"><span className="label">Google+</span></a></li>
                        </ul>


                        <div className="copyright">
                            <ul className="menu">
                                <li>&copy; Untitled. All rights reserved</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

        </main>
    );
}

export default Footer;