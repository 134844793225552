import React from 'react';

import {
  Program, ProgramInsert, ProgramUpdate,
  ProgramView,

  GroupPicture, GroupPictureInsert, GroupPictureUpdate,
  GroupPictureView,

  Activity, ActivityInsert, ActivityUpdate,
  ActivityView,
} from 'story';
const routes = [
  //프로그램
  {
    path: '/story/program/garden',
    renderer: (params = {}) => <Program {...params} />,
  },
  {
    path: '/story/program/forest',
    renderer: (params = {}) => <Program {...params} />,
  },
  {
    path: '/story/program/music',
    renderer: (params = {}) => <Program {...params} />,
  },
  {
    path: '/story/program/happy',
    renderer: (params = {}) => <Program {...params} />,
  },
  {
    path: '/story/program/community',
    renderer: (params = {}) => <Program {...params} />,
  },
  {
    path: '/story/program/view',
    renderer: (params = {}) => <ProgramView {...params} />,
  },
  {
    path: '/story/program/update',
    renderer: (params = {}) => <ProgramUpdate {...params} />,
  },
  {
    path: '/story/program/insert',
    renderer: (params = {}) => <ProgramInsert {...params} />,
  },
  //활동사진
  {
    path: '/story/groupPicture/groupPicture0',
    renderer: (params = {}) => <GroupPicture {...params} />,
  },
  {
    path: '/story/groupPicture/groupPicture1',
    renderer: (params = {}) => <GroupPicture {...params} />,
  },
  {
    path: '/story/groupPicture/groupPicture2',
    renderer: (params = {}) => <GroupPicture {...params} />,
  },
  {
    path: '/story/groupPicture/groupPicture3',
    renderer: (params = {}) => <GroupPicture {...params} />,
  },
  {
    path: '/story/groupPicture/groupPicture4',
    renderer: (params = {}) => <GroupPicture {...params} />,
  },
  {
    path: '/story/groupPicture/groupPicture5',
    renderer: (params = {}) => <GroupPicture {...params} />,
  },
  {
    path: '/story/groupPicture/view',
    renderer: (params = {}) => <GroupPictureView {...params} />,
  },
  {
    path: '/story/groupPicture/update',
    renderer: (params = {}) => <GroupPictureUpdate {...params} />,
  },
  {
    path: '/story/groupPicture/insert',
    renderer: (params = {}) => <GroupPictureInsert {...params} />,
  },
  //특별활동
  {
    path: '/story/activity/activity',
    renderer: (params = {}) => <Activity {...params} />,
  },
  {
    path: '/story/activity/view',
    renderer: (params = {}) => <ActivityView {...params} />,
  },
  {
    path: '/story/activity/update',
    renderer: (params = {}) => <ActivityUpdate {...params} />,
  },
  {
    path: '/story/activity/insert',
    renderer: (params = {}) => <ActivityInsert {...params} />,
  },
];

export default routes;
