import React from 'react';

import {Update} from './components';
const ActivityUpdate = () => {
  return (
          <Update />
  );
};

export default ActivityUpdate;
