import React from 'react';

import {Update} from './components';
const EduPlanUpdate = () => {
  return (
          <Update />
  );
};

export default EduPlanUpdate;
