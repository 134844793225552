const pages = {
  blog: [
    {
      title: '인사말',
      href: '/intro/intro',
    },
    {
      title: '원훈 및 교육목표',
      href: '/intro/eduGoal',
    },
    {
      title: '운영방침 및 현황',
      href: '/intro/schoolIntro',
    },
    {
      title: '어린이집 환경',
      href: '/intro/schoolEnv',
    },
    {
      title: '오시는 길',
      href: '/intro/comeMap',
    },
  ],
  company: [
    // {
    //   title: '교육 프로그램',
    //   href: '/edu/program',
    // },
    {
      title: '특색 프로그램',
      href: '/edu/disProgram/disProgram01',
    },
    {
      title: '특별활동',
      href: '/edu/eduPlan/eng',
    },
    // {
    //   title: '선생님 방',
    //   href: '/edu/teacher/teacher',
    // },
  ],
  account: [
    {
      title: '까꿍반',
      href: '/story/groupPicture/groupPicture0',
    },
    {
      title: '햇살반',
      href: '/story/groupPicture/groupPicture1',
    },
    {
      title: '행복반',
      href: '/story/groupPicture/groupPicture2',
    },
    {
      title: '지혜반',
      href: '/story/groupPicture/groupPicture3',
    },
    {
      title: '슬기반',
      href: '/story/groupPicture/groupPicture4',
    },
    {
      title: '창의반',
      href: '/story/groupPicture/groupPicture5',
    },
    // {
    //   title: '프로그램',
    //   href: '/story/program/garden',
    // },
    // {
    //   title: '특별활동',
    //   href: '/story/activity/activity',
    // },
  ],
  secondary: [
    {
      title: '어린이집 소식',
      href: '/news/word/word',
    },
    {
      title: '공지사항',
      href: '/news/notice/notice',
    },
    // {
    //   title: '식단표',
    //   href: '/news/menu/menu',
    // },
    // {
    //   title: '가정 통신문',
    //   href: '/news/letter/letter',
    // },
    // {
    //   title: '서식 다운로드',
    //   href: '/news/form/form',
    // },
  ],
  portfolio: [
    {
      title: '입소순위',
      href: '/rank/rank/rank',
    },
  ],
};

export default pages;
