import React from 'react';

import {View} from './components';
const ProgramView = () => {
  return (
          <View />
  );
};

export default ProgramView;
