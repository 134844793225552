import kakaoLogin from "./../img/kakao_login_medium_narrow.png"
import axios from "axios";

const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));
const Rest_api_key=   process.env.REACT_APP_KAKAO_REST_API_KEY; //REST API KEY
const redirect_uri =  process.env.REACT_APP_KAKAO_REDIRECT_URI; //Redirect URI
// oauth 요청 URL
const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`
const handleLogin = ()=>{
    window.location.href = kakaoURL
}

const logout = () =>{
    // localStorage.removeItem("accessToken");
    localStorage.clear();
    alert("로그아웃 되었습니다");
    window.location.href = "/";
}

const changeNickName= () =>{

    if (tokenExpiresIn) {
        return <a href="/changeNickName">my page</a>
    }
}

function Header(){
    return (
        <main>
            <div className="py-4">
                <div className="container">
                    <img height="28px" src={kakaoLogin} onClick={handleLogin}/>
                    {changeNickName()}
                    <h4><a onClick={logout}>로그아웃</a> </h4>
                    <p>여기는 Header 입니다</p>
                    <h1><a href="/" id="logo">부산경찰청어린이집</a></h1>
                </div>
            </div>

        </main>
    );
}

const H = () => {
    return (
        <header>
            <a href="/">홈</a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="/board">게시판</a>
            <hr/>
        </header>
    );
};
// export default Header;
export {Header,H}