          import Router from "../router/Router"
import "../css/main.css"
import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";

    function Home() {

        return (
            <main>
                <div className="py-4">
                    <div className="container">
                        <p>여기는 Main 입니다</p>
                        <section className="wrapper style1">
                            <div className="container">
                                <div className="row gtr-200">
                                    <section className="col-4 col-12-narrower">
                                        <div className="box highlight">
                                            <i className="icon solid major fa-paper-plane"></i>
                                            <h3>This Is Important</h3>
                                            <p>Duis neque nisi, dapibus sed mattis et quis, nibh. Sed et dapibus nisl
                                                amet mattis, sed a rutrum accumsan sed. Suspendisse eu.</p>
                                        </div>
                                    </section>
                                    <section className="col-4 col-12-narrower">
                                        <div className="box highlight">
                                            <i className="icon solid major fa-pencil-alt"></i>
                                            <h3>Also Important</h3>
                                            <p>Duis neque nisi, dapibus sed mattis et quis, nibh. Sed et dapibus nisl
                                                amet mattis, sed a rutrum accumsan sed. Suspendisse eu.</p>
                                        </div>
                                    </section>
                                    <section className="col-4 col-12-narrower">
                                        <div className="box highlight">
                                            <i className="icon solid major fa-wrench"></i>
                                            <h3>Probably Important</h3>
                                            <p>Duis neque nisi, dapibus sed mattis et quis, nibh. Sed et dapibus nisl
                                                amet mattis, sed a rutrum accumsan sed. Suspendisse eu.</p>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>

                        <div>
                            게시판 목록 출력
                            {/*{BoardList()}*/}
                        </div>
                    </div>
                </div>

            </main>
        );
    }

const BoardList = () => {
    const [boardList, setBoardList] = useState([]);

    const getBoardList = async () => {
        const resp = await (await axios.get('/api/board')).data; // 2) 게시글 목록 데이터에 할당
        // setBoardList(resp.data); // 3) boardList 변수에 할당
        setBoardList(resp); // 3) boardList 변수에 할당
        // console.log("boardList:"+boardList);
        // console.log("resp.data:"+resp.data);
        // console.log("resp:"+resp);

    }

    useEffect(() => {
        getBoardList(); // 1) 게시글 목록 조회 함수 호출
    }, []);

    return (
        <div>
            게시글 목록
        <ul>
            {boardList && boardList.map((board) => (
                // 4) map 함수로 데이터 출력
                <li key={board.idx}>
                    <Link to={`/board/${board.idx}`}>{board.title}</Link>
                </li>
            ))}
        </ul>
        </div>
    );
};


export default Home;