import React from 'react';

import {Insert} from './components';
const RankInsert = () => {
  return (
          <Insert />
  );
};

export default RankInsert;
