import Router from "../../router/Router"
import "../../css/main.css"
import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import Pagination from "react-js-pagination";
import styled from 'styled-components'
import {Link} from "react-router-dom";

function Main() {

    return (
        <div className="py-4">
            <div className="container">
                {ChangeGrade()}
            </div>
        </div>
    );
}

const ChangeGrade = () => {
    const [boardList, setBoardList] = useState([]);
    const accessToken = localStorage.getItem("accessToken"); // localStorage 이용해 accessToken을 가져옵니다.
    const tokenExpiresIn = JSON.parse(localStorage.getItem("tokenExpiresIn"));

    const [viewGrade,setViewGrade] = useState("HUMAN");

    //페이징 처리
    const [page, setPage] = useState(1);
    const [items, setItems] = useState(5);
    const handlePageChange = (page) => { setPage(page); };

    const getUserList = async () => {
        try {
            const resp = await (await axios.get("/api/userGradeList",{
                params:{grade:viewGrade},
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }));
            setBoardList(resp.data); // 3) boardList 변수에 할당
        } catch (error) {
            alert(error.response.data.resultMsg);
            window.location.href = "/"
        }
    }

    const changeViewRole= (grade) => {
        setViewGrade(grade);
    }

    const showBtnName = (userId)=>{
        switch (viewGrade){
            case "HUMAN" :
                return <button onClick={() => changUserGrade(userId)}>학부모로 등업</button>
            case "MOM" :
                return <button onClick={() => changUserGrade(userId)}>선생님으로 등업</button>
            case "TEACHER":
                return <button onClick={() => changUserGrade(userId)}>학부모로 변경</button>

        }
    }
    const changUserGrade = async (id) => {
        try {//백엔드에 데이터 보내기
            let data = {
                id : id,
                role : viewGrade,
            }

            const result = await axios.post('/api/changeGrade',  JSON.stringify(data), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                }
            });
            alert('변경되었습니다.');
            window.location.reload();
        } catch (error) {
            console.log('실패했어요ㅠ');
            alert(error.response.data.resultMsg);
            // window.location.reload();
            window.location.href = "/"
        }
    }

    const  checkTokenExpire = () => {
        const parseTokenExpiresIn = tokenExpiresIn;
        if(!parseTokenExpiresIn){
            
            return null;
        }

        const now = new Date();

        if (now.getTime()>parseTokenExpiresIn){
            localStorage.removeItem('accessToken');
            localStorage.removeItem('tokenExpiresIn');
            
            return null
        }
        
        return parseTokenExpiresIn
    }



    useEffect(() => {
        getUserList(); // 1) 게시글 상세정보 함수 호출
    }, [viewGrade]);

    useEffect(() => {
        checkTokenExpire(); // 1) 게시글 목록 조회 함수 호출
    }, []);

    return (
        <div>
            <button><Link to={"/changeNickName"}>닉네임 변경</Link></button>
            <button><Link to={"/changeGrade"}>권한 변경</Link></button>
            <div>권한변경</div>
            <button onClick={()=>changeViewRole("HUMAN")}>일반인</button>
            <button onClick={()=>changeViewRole("MOM")}>학부모</button>
            <button onClick={()=>changeViewRole("TEACHER")}>선생님</button>
            {
                boardList.slice(
                    10 * (page - 1),
                    10 * (page - 1) + 10
                ).map((v, i) => {
                    return (
                        <div key={i}>
                            {/*<label>가입일:{v.createdDate}, 닉네임 = {v.nickName}, 등급 ={v.role}*/}
                            <label>가입일:{v.createdDate},  닉네임 = {v.nickName}
                                {showBtnName(v.id)}
                            </label>

                        </div>
                    )
                })
            }
            <PaginationBox>
                <Pagination
                    activePage={page}
                    itemsCountPerPage={items}
                    totalItemsCount={boardList.length - 1}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}>
                </Pagination>
            </PaginationBox>
        </div>
    );
};
const PaginationBox = styled.div`
    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    ul.pagination li {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 1px solid #e2e2e2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
    }

    ul.pagination li:first-child {
        border-radius: 5px 0 0 5px;
    }

    ul.pagination li:last-child {
        border-radius: 0 5px 5px 0;
    }

    ul.pagination li a {
        text-decoration: none;
        color: #337ab7;
        font-size: 1rem;
    }

    ul.pagination li.active a {
        color: white;
    }

    ul.pagination li.active {
        background-color: #337ab7;
    }

    ul.pagination li a:hover,
    ul.pagination li a.active {
        color: blue;
    }
`
export default Main;