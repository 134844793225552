import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          부산경찰청어린이집
          <br />
          call: 051-505-9330
          E-mail: pns9330@hanmail.net
        </Typography>
        {/*<Link*/}
        {/*  align={'center'}*/}
        {/*  // variant={'caption'}*/}
        {/*  color="text.secondary"*/}
        {/*  // component={'p'}*/}
        {/*>*/}
        <a href="https://www.flaticon.com/kr/free-icons/" title="찾기 아이콘">아이콘 제작자: Freepik, Konkapp, Flat Icons,
          Eucalyp, mangsaabguru, photo3idea_studio, GOWI, Dewi Sari, gravisio, zafdesign, Pixel perfect, surang -
          Flaticon</a>
        {/*</Link>*/}
      </Grid>
    </Grid>
  );
};

export default Footer;
