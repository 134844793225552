import React from 'react';

import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  Story,
} from './components';

const Intro = () => {
  return (
    <Main>
      <Container>
        <Story />
      </Container>
    </Main>
  );
};

export default Intro;
